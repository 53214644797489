import { authHeader } from './auth-header';
import test from "@/app.config.json";

export const userService = {
    login,
    logout,
    register,
    getAll,
    userChatSrv,
    reset,
    change,
    baseUrl:test.baseUrl,
};

function login(email, password) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userName:email, password:password })
    };

    return fetch(this.baseUrl+`/Auth/Login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.token) 
            {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
                localStorage.setItem("jwt", user.jwt);
            }
            return user;
        });
}
function reset(email) {
    return fetch(this.baseUrl+`/Auth/Forgot?email=`+email).then(handleResponse);
}
function change(user) {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };
    return fetch(this.baseUrl+`/Auth/Reset`, requestOptions).then(handleResponse);
}
function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('jwt');
    localStorage.removeItem('group');
}
function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };
    return fetch(this.baseUrl+`/Auth/Register`, requestOptions).then(handleResponse);
}
function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`/users`, requestOptions).then(handleResponse);
}
function userChatSrv(userId) 
{
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
   return fetch(this.baseUrl+'/General/Chat/GetByUser/'+userId,requestOptions).then(handleResponse);
    
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) 
        {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}